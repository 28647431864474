import './set-public-path';
import React from 'react';
import ReactDOM from 'react-dom';
import singleSpaReact from 'single-spa-react';
import singleSpaCanopy from 'single-spa-canopy';
import { Scoped } from 'kremling';

Scoped.defaultNamespace = 'dolby-ui';

const reactLifecycles = singleSpaReact({
  React,
  ReactDOM,
  loadRootComponent: () => import(/* webpackChunkName: "Root" */ './root.component').then(mod => mod.Root),
  domElementGetter,
});

export const toggles = [];

function fetchToggles () {
  return SystemJS.import('feature-toggles!sofe').then(fui => fui.fetchFeatureToggles(...toggles));
}

const canopyLifecycles = singleSpaCanopy({
  domElementGetter,
  childAppName: 'dolby-ui',
  mainContentTransition: false,
  featureToggles: toggles,
});

export const bootstrap = [
  canopyLifecycles.bootstrap,
  reactLifecycles.bootstrap,
];

export const mount = [
  reactLifecycles.mount,
  canopyLifecycles.mount,
];

export const unmount = [
  reactLifecycles.unmount,
  canopyLifecycles.unmount,
];


function domElementGetter() {
  let dolbyUI = document.getElementById('dolby-ui');
  if (dolbyUI) return dolbyUI;

  // If the element doesn't exist then create it
  let container = document.createElement('div');
  container.setAttribute('id', 'dolby-ui');
  document.body.appendChild(container);
  return container;
}